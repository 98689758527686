<template>
  <el-dialog
    append-to-body
    class="common-dialog"
    :close-on-click-modal="false"
    height="10vh"
    max-height="480"
    style="padding-bottom: 80px"
    :title="title"
    :visible.sync="dialogFormVisible"
    width="500px"
    @close="close"
  >
    <el-form
      v-if="pageState == 'edit'"
      ref="form"
      class="activitiesForm"
      label-position="right"
      label-width="80px"
      :model="form"
      :rules="rules"
    >
      <el-form-item label="报告名称:" prop="report_name">
        <el-input
          v-model.trim="form.report_name"
          class="width200"
          clearable
          maxlength="50"
          placeholder="请输入报告名称"
          show-word-limit
        />
      </el-form-item>
    </el-form>

    <div v-else class="report_box">
      <div
        v-if="dialogFormVisible"
        class="abow_dialog"
        style="padding: 10px; height: 67vh"
      >
        <div class="floorType right_title_content_for">
          <!-- <div class="card-header">
            <span>{{ ele_map.map_name }}</span>
          </div>

          <div class="top_images">
            <img
              border-radius="10px"
              mode="widthFix"
              :src="ele_map.map_image"
              width="100%"
            />
          </div> -->
          <!-- 号苗清单 -->
          <div class="map_name padded-t30 padded-b30">号苗清单</div>
          <select-table
            :accessories="nxpTabelAccessories"
            :showPagination="false"
            only-key-id="id"
            :show-btn="true"
            :table-columns="tableColumns"
            :table-data="cloudInoculum"
            :table-loading="tableLoading"
          />
          <div class="map_name padded-t30 padded-b30">采购清单</div>
          <div v-for="(ele, idx) in shop_bind_item" :key="idx">
            <select-table
              :accessories="nxpTabelAccessories"
              :showPagination="false"
              only-key-id="id"
              :show-btn="true"
              :table-columns="column6"
              :table-data="ele.cloudInoculum"
            />
          </div>

          <!-- <div
            v-for="(item_map, idx) in ele_map.project_map_item_info"
            :key="idx"
            class="map_box"
            :value="item_map.item_id"
          >
            <div class="flex-box fw-blod">
              <div class="flex-box-item text-al report_title item_name left_name">
                {{ item_map.item_info.name }}
              </div>
              <div class="flex-box-item text-ac report_title">设计参数</div>
              <div class="flex-box-item text-ar report_title right_name">实际参数</div>
            </div>
            <div class="flex-box c-666 margin-b8">
              <div class="flex-box-item text-al report_txt left_name">高度</div>
              <div class="flex-box-item text-ac report_txt">
                {{
                  item_map.variety_info.height_min +
                  "~" +
                  item_map.variety_info.height_max
                }}
              </div>
              <div class="flex-box-item text-ar report_txt right_name">
                {{ item_map.item_info.height_min + "~" + item_map.item_info.height_max }}
              </div>
            </div>
            <div class="flex-box c-666 margin-b8">
              <div class="flex-box-item text-al report_txt left_name">冠幅</div>
              <div class="flex-box-item text-ac report_txt">
                {{ item_map.variety_info.gf_min + "~" + item_map.variety_info.gf_max }}
              </div>
              <div class="flex-box-item text-ar report_txt right_name">
                {{ item_map.item_info.gf_min + "~" + item_map.item_info.gf_max }}
              </div>
            </div>
            <div class="flex-box c-666 margin-b8">
              <div class="flex-box-item text-al report_txt left_name">胸地径</div>
              <div class="flex-box-item text-ac report_txt">
                {{ item_map.variety_info.xdj_min + "~" + item_map.variety_info.xdj_max }}
              </div>
              <div class="flex-box-item text-ar report_txt right_name">
                {{ item_map.item_info.xdj_min + "~" + item_map.item_info.xdj_max }}
              </div>
            </div>
            <div class="flex-box c-666 margin-b8">
              <div class="flex-box-item text-al report_txt left_name">分支点数</div>
              <div class="flex-box-item text-ac report_txt">
                {{ item_map.variety_info.fzd_num }}
              </div>
              <div class="flex-box-item text-ar report_txt right_name">
                {{ item_map.item_info.fzd_num }}
              </div>
            </div>
            <div class="flex-box c-666 margin-b8">
              <div class="flex-box-item text-al report_txt left_name">分支点高度</div>
              <div class="flex-box-item text-ac report_txt">
                {{
                  item_map.variety_info.fzd_height_min +
                  "~" +
                  item_map.variety_info.fzd_height_max
                }}
              </div>
              <div class="flex-box-item text-ar report_txt right_name">
                {{
                  item_map.item_info.fzd_height_min +
                  "~" +
                  item_map.item_info.fzd_height_max
                }}
              </div>
            </div>
            <div class="flex-box c-666 margin-b8">
              <div class="flex-box-item text-al report_txt left_name">价格</div>
              <div class="flex-box-item text-ac report_txt">
                {{ item_map.variety_info.price }}
              </div>
              <div class="flex-box-item text-ar report_txt right_name">
                {{ item_map.item_info.price }}
              </div>
            </div>
            <div class="container">
              <div
                v-for="subItem in item_map.images_data"
                :key="subItem.id"
                :class="item_map.item_info.imgLen == 1 ? 'single-item' : 'double-item'"
                :value="subItem.id"
              >
                <img
                  border-radius="10px"
                  mode="widthFix"
                  :src="subItem.image_src"
                  width="100%"
                />
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>

    <template #footer>
      <el-button v-if="pageState != 'view'" type="primary" @click="save">
        确 定
      </el-button>
      <el-button @click="close">
        {{ pageState == "add" ? "取 消" : "关 闭" }}
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import {
  updateReportDataList,
  delReportDataList,
  queryReportDetail,
} from "@/project/eztree/baseApi/commonApi";

export default {
  name: "AddEdit",
  components: {},
  props: {},
  data() {
    return {
      dialogTableVisible: false,
      pageObj: {
        pageType: "components",
        selectType: "checkBox",
      },
      tableColumns: [
        { prop: "variety_name", fixed: true, width: 150, label: "名称" },
        { prop: "minus_num", label: "待号苗", width: 70, align: "center" },
        { prop: "add_num", label: "已号苗", width: 70, align: "center" },
        { prop: "created_num", label: "总数量", width: 70, align: "center" },
      ],
      column6: [
        { prop: "item_name", fixed: false, width: 150, label: "名称" },
        { prop: "price", label: "价格", width: 70, align: "center" },
        { prop: "count", label: "数量", width: 70, align: "center" },
        { prop: "total", label: "小计", width: 70, align: "center" },
      ],
      shop_bind_item: null,
      cloudInoculum: [],
      unused_item_list: [],
      form: {
        id: "",
        report_name: "",
      },
      checkValid: false,
      rules: {
        report_name: [{ required: true, trigger: "blur", message: "请输入名称" }],
      },
      pageState: "add",
      title: "",
      dialogFormVisible: false,
      tableLoading: false,
      tableData: [],
      nxpTabelAccessories: {
        pageType: "components",
        selectType: "checkBox",
        pageNo: 1,
        pageSize: 15,
        total: 0,
        height: 300,
      },
    };
  },
  watch: {},
  created() {},
  methods: {
    // 打开弹框
    showEdit(row, action) {
      this.pageState = action;
      let params = Object.assign(
        {},
        { report_id: row.report_id, report_name: row.report_name }
      );
      this.form = params;
      console.log(params, row, this.form);
      if (action == "delete") {
        this.$confirm("确认删除吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            delReportDataList(params).then(({ code, data }) => {
              if (code == "200") {
                this.$baseMessage("删除成功", "success", "vab-hey-message-success");
                this.$emit("fetch-data");
              }
            });
          })
          .catch(() => {});
        return false;
      }
      this.dialogFormVisible = true;
      this.title = row.report_name;
      if (action != "add") {
        queryReportDetail({
          report_id: row.report_id,
        }).then(({ code, data }) => {
          if (code == "200") {
            const {
              report_bind_list,
              shop_bind_item,
              report_info,
              project_bind_variety,
              un_bind_list,
            } = data;
            const { asyncShow, user_id } = report_info;
            if (report_info) {
              // 号苗清单
              this.cloudInoculum =
                project_bind_variety.length > 0 &&
                project_bind_variety.map((item) => {
                  return {
                    variety_name: item.variety_name,
                    price: item.price,
                    status: item.status,
                    created_num: item.created_num,
                    add_num: item.add_num,
                    minus_num:
                      item.created_num == 0
                        ? "-"
                        : item.created_num - item.add_num < 0
                        ? "0"
                        : item.created_num - item.add_num,
                    spec_text:
                      (item.xdj_status == 1 ? "D" : item.xdj_status == 2 ? "Φ" : "") +
                      item.xdj +
                      "/" +
                      "H" +
                      item.height +
                      "/P" +
                      item.gf,
                    item_id: item.item_id,
                    user_id,
                  };
                });
              let shop_list = [];
              Object.keys(shop_bind_item).forEach(function (key) {
                let objlist = Object.assign(
                  {},
                  {
                    shop_id: shop_bind_item[key].shop_id,
                    shop_name: shop_bind_item[key].shop_name,
                    cloudInoculum:
                      shop_bind_item[key].computed_list &&
                      shop_bind_item[key].computed_list.map((item) => {
                        return {
                          item_name:
                            item.item_name +
                            "(" +
                            (item.xdj_status == 1
                              ? "D"
                              : item.xdj_status == 2
                              ? "Φ"
                              : "") +
                            item.xdj +
                            "/" +
                            "H" +
                            item.height +
                            "/P" +
                            item.gf +
                            ")",
                          count: item.count,
                          price: parseInt(item.price),
                          total: parseInt(item.price) * parseInt(item.count),
                          item_id: item.item_id,
                          user_id,
                        };
                      }),
                  }
                );
                shop_list.push(objlist);
              });
              // 苗店采购清单
              this.shop_bind_item = shop_list;
              this.asyncChecked = asyncShow == 2 ? true : false;
              report_bind_list.forEach((ele) => {
                ele.map_key = "map" + ele.map_id;
              });
              this.source = report_bind_list;
              this.report_info = report_info;
              // 报告绑定的苗木
              this.report_bind_list = report_bind_list;
              // 待用苗木
              this.un_bind_list = un_bind_list;
              console.log(this.report_bind_list, this.un_bind_list);
            } else {
              uni.showToast({
                title: "查询失败，请重试",
                icon: "none",
              });
            }
          }
        });
      }
    },
    close() {
      this.form = this.$options.data().form;
      this.dialogFormVisible = false;
    },
    save() {
      this.$refs["form"].validate((valid) => {
        //都调成功以后执行的操作
        if (valid) {
          console.log("params", this.form);
          updateReportDataList(this.form).then(({ code, data }) => {
            if (code == "200") {
              this.$baseMessage("操作成功", "success", "vab-hey-message-success");
              this.$emit("fetch-data");
              this.close();
            }
          });
        } else {
          this.$baseMessage("必填项未填写", "error", "vab-hey-message-error");
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.report_box {
  position: relative;

  .card-header {
    height: 60px;
    text-align: center;
    line-height: 60px;
    font-size: 22px;
  }

  .map_box {
    position: relative;
    font-size: 16px;

    .report_title {
      height: 40px;
      line-height: 40px;
    }

    .report_txt {
      height: 30px;
      line-height: 30px;
      color: #8c8c8c;
    }
  }
}

/* flexbox */
.flex-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.flex-box-vertical {
  height: 100%;
  flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
}

.flex-box-vertical .yd-flexbox-item {
  width: 100%;
}

.flex-box-item {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.flex-box-item-start {
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  justify-self: flex-end;
}

.flex-box-item-center {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.flex-box-item-end {
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
}

.flex-box-justify-between {
  -webkit-justify-content: space-between;
  -ms-justify-content: space-between;
  justify-content: space-between;
}

.flex-box-justify-start {
  -webkit-justify-content: flex-start !important;
  -ms-justify-content: flex-start !important;
  justify-content: flex-start !important;
}

.flex-box-justify-center {
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
}

.flex-box-justify-end {
  -webkit-justify-content: flex-end;
  -ms-justify-content: flex-end;
  justify-content: flex-end;
}

.fw-blod {
  font-weight: 600;
}

.item_name {
  color: #14c4ad;
}

.left_name {
  text-align: left;
  margin-left: 20px;
}

.right_name {
  text-align: right;
  margin-right: 20px;
}

.text-ac {
  text-align: center;
}

.container {
  display: flex;
  flex-wrap: wrap;
  border: 20px;
  position: relative;
  box-sizing: border-box;

  .ele {
    width: 48%;
    margin: 20px 1%;
  }

  .single-item:last-child {
    width: 100% !important;
  }
}
</style>
