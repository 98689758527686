var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "common-dialog",
      staticStyle: { "padding-bottom": "80px" },
      attrs: {
        "append-to-body": "",
        "close-on-click-modal": false,
        height: "10vh",
        "max-height": "480",
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "500px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _vm.pageState != "view"
                ? _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.save } },
                    [_vm._v(" 确 定 ")]
                  )
                : _vm._e(),
              _c("el-button", { on: { click: _vm.close } }, [
                _vm._v(
                  " " + _vm._s(_vm.pageState == "add" ? "取 消" : "关 闭") + " "
                ),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm.pageState == "edit"
        ? _c(
            "el-form",
            {
              ref: "form",
              staticClass: "activitiesForm",
              attrs: {
                "label-position": "right",
                "label-width": "80px",
                model: _vm.form,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "报告名称:", prop: "report_name" } },
                [
                  _c("el-input", {
                    staticClass: "width200",
                    attrs: {
                      clearable: "",
                      maxlength: "50",
                      placeholder: "请输入报告名称",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.form.report_name,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.form,
                          "report_name",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.report_name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _c("div", { staticClass: "report_box" }, [
            _vm.dialogFormVisible
              ? _c(
                  "div",
                  {
                    staticClass: "abow_dialog",
                    staticStyle: { padding: "10px", height: "67vh" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "floorType right_title_content_for" },
                      [
                        _c(
                          "div",
                          { staticClass: "map_name padded-t30 padded-b30" },
                          [_vm._v("号苗清单")]
                        ),
                        _c("select-table", {
                          attrs: {
                            accessories: _vm.nxpTabelAccessories,
                            showPagination: false,
                            "only-key-id": "id",
                            "show-btn": true,
                            "table-columns": _vm.tableColumns,
                            "table-data": _vm.cloudInoculum,
                            "table-loading": _vm.tableLoading,
                          },
                        }),
                        _c(
                          "div",
                          { staticClass: "map_name padded-t30 padded-b30" },
                          [_vm._v("采购清单")]
                        ),
                        _vm._l(_vm.shop_bind_item, function (ele, idx) {
                          return _c(
                            "div",
                            { key: idx },
                            [
                              _c("select-table", {
                                attrs: {
                                  accessories: _vm.nxpTabelAccessories,
                                  showPagination: false,
                                  "only-key-id": "id",
                                  "show-btn": true,
                                  "table-columns": _vm.column6,
                                  "table-data": ele.cloudInoculum,
                                },
                              }),
                            ],
                            1
                          )
                        }),
                      ],
                      2
                    ),
                  ]
                )
              : _vm._e(),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }